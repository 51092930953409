import React from 'react';
import { CityLocation, getCountryFromLocale } from '../src/helpers/location';
import { getCommonFlippData, RouteProps } from '../src/helpers/common';
import Home from '../src/components/home';

interface RootRouteProps extends RouteProps {
  cities: CityLocation[];
}

export default function RootRoute({ cities }: RootRouteProps): JSX.Element {
  return <Home cities={cities} />;
}

export const getStaticProps = async ({ locale }) => {
  const countryFromLocale = getCountryFromLocale(locale);
  const { cities } = await getCommonFlippData(countryFromLocale, locale);
  return { props: { cities } };
};
